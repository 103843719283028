import React, { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";

const HowItWorks = ({ classes = "md:py-24 py-12", bgWhite }) => {
    const tabItem = [
        {
            id: 1,
            text: "Mobile",
            URL: 'https://www.youtube.com/embed/TIty9a_Mj3A?si=1s5kC8auJDdCn-kH&rel=0&showinfo=0&autoplay=1',
            img: 'https://img.youtube.com/vi/TIty9a_Mj3A/0.jpg'
        },
        {
            id: 2,
            text: "Desktop",
            URL: 'https://www.youtube.com/embed/JR4KHfqw-oE?si=IegL0Ui-WiHscfuo',
            img: 'https://img.youtube.com/vi/JR4KHfqw-oE/0.jpg'
            // URL: 'https://www.youtube.com/embed/qATqEehWzzU',
            // img: 'https://img.youtube.com/vi/qATqEehWzzU/0.jpg'
        }
    ];
    
    const [videoModal, setvideoModal] = useState(false);
    const [url, setUrl] = useState(tabItem[0]['URL']);

    const [itemActive, setItemActive] = useState(1);

    const [img, setImg] = useState(tabItem[0]['img']);

    return (
        <section className={classes}>
            {/* <!-- Hot Bids --> */}
            {bgWhite && (
                <picture className="pointer-events-none absolute inset-0 -z-10 dark:hidden">
                    <img
                        src="/images/gradient_light.jpg"
                        alt="gradient"
                        className="h-full w-full"
                    />
                </picture>
            )}
            <div className="container">
                <HeadLine
                    text="How Davinte Works"
                    classes="font-display text-jacarta-700 mb-6 text-center text-2xl md:text-3xl lg:text-4xl dark:text-white"
                    pera="Learn how it works in 1 minute!"
                />
                {/* <iframe frameborder="0" scrolling="no" marginheight="0" marginwidth="0" width="788.54" height="443" type="text/html" src="https://www.youtube.com/embed/TIty9a_Mj3A?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"></iframe> */}
                <div className="relative">
                    {/* <div className="tabs">
                        <div className="no-scrollbar nav nav-tabs scrollbar-custom dark:border-jacarta-600 border-jacarta-100 mb-12 flex items-center justify-center overflow-x-auto overflow-y-hidden border-b pb-px">
                            {tabItem.map(({ id, text, URL, img }) => {
                                return (
                                    <div
                                        className="nav-item"
                                        key={id}
                                        onClick={() => {
                                            setItemActive(id);
                                            setUrl(URL);
                                            setImg(img);
                                        }}>
                                        <button
                                            className={
                                                itemActive === id
                                                    ? "nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                                                    : "nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
                                            }>
                                            <span className="font-display text-base font-medium">
                                                {text}
                                            </span>
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div> */}
                    {/* <!-- Slider --> */}
                    <div className="relative mx-auto max-w-[50rem] py-3">
                        <div className="container">
                            <figure className="relative overflow-hidden rounded-lg before:absolute before:inset-0 before:bg-jacarta-900/25">
                                <img
                                    src={img}
                                    className="w-full"
                                    alt="video"
                                />
                                <button
                                    onClick={() => {
                                        setvideoModal(true);
                                    }}
                                    className="js-video-modal-trigger absolute top-1/2 left-1/2 flex h-24 w-24 -translate-y-1/2 -translate-x-1/2 items-center justify-center rounded-full border-2 border-white transition-transform will-change-transform hover:scale-90"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24"
                                        height="24"
                                        className="h-8 w-8 fill-white"
                                    >
                                        <path fill="none" d="M0 0h24v24H0z"></path>
                                        <path d="M19.376 12.416L8.777 19.482A.5.5 0 0 1 8 19.066V4.934a.5.5 0 0 1 .777-.416l10.599 7.066a.5.5 0 0 1 0 .832z"></path>
                                    </svg>
                                </button>
                            </figure>
                        </div>
                    </div>
                    <div
                        className={
                            videoModal ? "modal lightbox fade show block" : "modal lightbox fade"
                        }
                    >
                        <div className="modal-dialog modal-dialog-centered modal-xl w-full">
                            <div className="modal-content border-0 bg-transparent">
                                <div className="modal-body p-0 relative">
                                    <button
                                        onClick={() => {
                                            setvideoModal(false);
                                            setUrl(null);
                                        }}
                                        type="button"
                                        className="btn-close position-absolute top-0 end-0 p-3 z-10"
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 16 16"
                                            fill="#fff"
                                            className="h-6 w-6"
                                        >
                                            <path d="M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z"></path>
                                        </svg>
                                    </button>
                                    <div
                                        id="lightboxCarousel-d7ewe4ig"
                                        className="lightbox-carousel carousel"
                                    >
                                        <div className="carousel-inner">
                                            <div className="carousel-item active">
                                                <div className="position-absolute top-50 start-50 translate-middle text-white">
                                                    <div className="spinner-border" role="status"></div>
                                                </div>
                                                <div className="ratio ratio-16x9">
                                                    <iframe
                                                        src={url}
                                                        title="YouTube video player"
                                                        allow="fullscreen; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture;"
                                                    ></iframe>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- end hot bids --> */}
        </section>
    );
};

export default HowItWorks;
