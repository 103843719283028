import React, { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import Image from 'next/image'
import { defaultBlurData } from "../../utils/common"

const HeroSlogan = ({ classes = "md:py-24 py-12", bgWhite }) => {
  const tabItem = [
    {
      id: 1,
      text: "Mobile",
      URL: "https://www.youtube.com/embed/TIty9a_Mj3A?si=1s5kC8auJDdCn-kH&rel=0&showinfo=0&autoplay=1",
      img: "https://img.youtube.com/vi/TIty9a_Mj3A/0.jpg",
    },
    {
      id: 2,
      text: "Desktop",
      URL: "https://www.youtube.com/embed/JR4KHfqw-oE?si=IegL0Ui-WiHscfuo",
      img: "https://img.youtube.com/vi/JR4KHfqw-oE/0.jpg",
      // URL: 'https://www.youtube.com/embed/qATqEehWzzU',
      // img: 'https://img.youtube.com/vi/qATqEehWzzU/0.jpg'
    },
  ];

  return (
    <section
      className={`${classes} relative md:py-24 py-12`}
    >
      <div
        className="full-slider"
      >
          <HeadLine
            text="THE MOST ACCURATE AI RENDERING ENGINE IN THE MARKET"
            classes="font-hero-main font-title-small text-jacarta-700 mb-2 px-5 md:px-10 md:mb-6 text-center  text-4xl md:text-5xl lg:text-6xl dark:text-white"
            pera="From sketch to final rendering in SECONDS!"
          />
          <div className="flex flex-col md:flex-row justify-center">
            {/* <!-- Image --> */}
            <div className="w-full md:w-1/2 p-10 flex justify-center">
              <Image width={1000} height={554} alt="Davinte.AI - THE MOST ACCURATE AI RENDERING ENGINE IN THE MARKET" priority={true} 
                // placeholder='blur'
                // blurDataURL={defaultBlurData}
                src="/images/page-title/landing_sketch.jpg" className="border-1 border-jacarta-100" />
            </div>
            <div className="w-full md:w-1/2 p-10 flex justify-center">
              <Image width={1000} height={554} 
                // placeholder='blur'
                // blurDataURL={defaultBlurData} 
                alt="Davinte.AI - From sketch to final rendering in SECONDS" priority={true} src="/images/page-title/landing_rendering_1.jpg" />
            </div>
          </div>
      </div>
    </section>
  );
};

export default HeroSlogan;
