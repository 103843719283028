import Modal from '@mui/material/Modal';
import DesignCore from "./design_core_minimalist";
import ModalWrapper from '../modal/modalWrapper';

export default function DesignWrapper(props) {
  // const [modalOpen, setModalOpen] = useState(false);
  const { addIdea, recentDesign, panelPosition, modalOpen, setModalOpen, useCase, actionType, actionData, setLoading, loading } = props;

  return (
    <ModalWrapper modalOpen={modalOpen} setModalOpen={setModalOpen} screen_width={"w-11/12 sm:w-10/12 md:w-9/12 max-h-screen lg:max-w-4xl xl:max-w-5xl"}>
      <DesignCore
        panelPosition={panelPosition}
        recentDesign={recentDesign}
        addIdea={addIdea}
        setSelfIsClose={setModalOpen}
        useCase={useCase}
        actionType={actionType}
        actionData={actionData}
        setLoading={setLoading}
        loading={loading}
      />
    </ModalWrapper>
  )
}
