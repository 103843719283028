import React, { useRef } from "react";
import { HeadLine } from "../component";
import { useSelector, useDispatch } from "react-redux";
import {
  loginModalShow,
  plansModalShow,
} from "../../redux/counterSlice";
import { useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import ExploreHeroItem from "./exploreHeroItems/exploreHeroItems";

export default function ExploreHero(props) {
  const dispatch = useDispatch();
  const { subStatus } = useSelector((state) => state.counter);
  const { data: session, status } = useSession();
  const router = useRouter();

  function handleLoadMore() {
    if (!session) {
      dispatch(loginModalShow());
    } else if (!subStatus) {
      dispatch(plansModalShow());
    } else {
      router.push({
        pathname: "/explore",
      });
    }
  }

  const { designs } = props;
  const exploreDiv = useRef(null);

  return (
    <section className="relative dark:bg-jacarta-800 md:py-24 py-12 px-2">
      <div ref={exploreDiv} id="exploreDiv" className="mx-auto max-w-[150rem]">
        <HeadLine
          text="Top Community Renders"
          classes="font-hero-main font-title-small text-jacarta-700 mb-2 px-5 md:px-10 md:mb-6 text-center text-5xl lg:text-6xl dark:text-white"
          pera="The limit is your imagination"
        />
        <div className="overflow-hidden md:h-explore-tall h-explore-short rounded-b md:px-10 pt-5">
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 2, 750: 3, 900: 4, 1100: 5, 1500: 6 }}
          >
            <Masonry gutter="0.5rem">
              {designs &&
                designs.length > 0 &&
                designs.map((design, index) => {
                  const idea = design.ideas.filter(
                    (idea) => idea.likes.length > 0
                  )[0];
                  return (
                    <ExploreHeroItem idea={idea} design={design} key={index}/>
                  );
                })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
        <div className="w-full flex justify-center my-10">
          <button
            onClick={() => handleLoadMore()}
            className="dark:bg-jacarta-700 dark:border-jacarta-600 w-72	border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 flex items-center justify-center rounded-md border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent"
          >
            <span>Load more ...</span>
          </button>
        </div>
      </div>
    </section>
  );
}
