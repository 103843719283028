import React from "react";
import {
  Hero,
  HeroFull
} from "../../components/component";
import HeroSlogan from "../../components/hero/heroSlogan.js"
import HeroSteps from "../../components/hero/heroSteps.js"
import HowItWorks from "../../components/hero/howItWorks";
import KeyFeatures from "../../components/hero/keyFeatures";
import Meta from "../../components/Meta";
import ExploreHero from "../../components/hero/exploreHero.js";
import DesignWrapper from "../../components/design/design_wrapper";
import {
  CoreDesignPosition,
  CoreDesignUseCase,
  IdeasActions
} from "../../utils/enums";
import PlanHero from "../../components/plans/planHero";


const Home_1 = (props) => {
  const { designs } = props;
  const [showDesign, setShowDesign] = React.useState(false);
  const images = [{
    url: 'https://davinte.nyc3.cdn.digitaloceanspaces.com/assets/hero/hero-davinte-ai-desktop.jpg',
    alt: 'Discover Davinte.AI, the cutting-edge AI Rendering Engine for professionals. Create stunning renderings of your space in seconds with our generative AI technology. Experience the future of AI rendering today!',
    type: "image/jpeg",
    width: 770,
    height: 600,
  }]

  return (
    <main>
      <Meta title="Home" images={images} />
      <HeroFull showDesign={setShowDesign} />
      <HeroSlogan />
      <HeroSteps />
      {/* <HowItWorks />
      <KeyFeatures /> */}
      <PlanHero />
      <ExploreHero designs={designs} />
      <DesignWrapper
        panelPosition={CoreDesignPosition.CENTERED}
        setModalOpen={setShowDesign}
        actionType={IdeasActions.NEW_DESIGN}
        modalOpen={showDesign}
        useCase={CoreDesignUseCase.UPLOAD_NEEDED}
      />
    </main>
  );
};

export default Home_1;
