import React from "react";
import Image from 'next/image'
import { defaultBlurData } from "../../utils/common"

const HeroStep = (prop) => {
  const { counter, title, subTitle, imgSrc, whiteImgSrc } = prop;
  return (
    <div className="flex flex-col md:flex-row justify-between">
      <div className="w-full flex flex-col justify-center px-10 md:p-10">
        <div className="w-full flex justify-start">
          <div className="w-fit">
            <h2 className="font-hero-main pr-5 font-title-small text-jacarta-700 text-center text-9xl dark:text-white">
              {counter}
            </h2>
          </div>
          <div className="w-fit">
            <div className="flex flex-col justify-between">
              <h2 className="font-hero-main font-title-small text-jacarta-700 dark:text-white text-left text-3xl md:text-4xl pt-4">
                {title}
              </h2>
              <h2 className="text-jacarta-700 text-md md:text-md dark:text-white text-left pt-2">
                {subTitle}
              </h2>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full p-10 flex justify-end h-auto relative">
        <Image alt="Davinte.AI - How to guide" width={800} height={800}  priority={true} src={imgSrc} className="w-full max-w-sm dark:block hidden rounded overflow-hidden dark:border-1 dark:border-jacarta-600 object-cover" />
        <Image alt="Davinte.AI - How to guide" width={800} height={800}  priority={true} src={whiteImgSrc? whiteImgSrc : imgSrc} className="w-full max-w-sm block dark:hidden rounded overflow-hidden border-1 border-jacarta-100 object-cover" />
      </div>
    </div>
  );
};

export default HeroStep;
