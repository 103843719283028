import React, { useState } from "react";
import "swiper/css";
import "swiper/css/navigation";
import { HeadLine } from "../component";
import "tippy.js/dist/tippy.css";
import HeroStep from "./heroStep";

const HeroSteps = ({ classes = "md:py-24 py-12" }) => {
  return (
    <section
      className={`${classes} dark:bg-jacarta-800 relative md:py-24 py-12 flex justify-center`}
    >
      <div className="w-full max-w-screen-2xl">
        <HeadLine
          text="HOW DAVINTE WORKS"
          classes="font-hero-main font-title-small text-jacarta-700 mb-2 md:mb-6 px-5 md:px-10 text-center text-5xl lg:text-6xl dark:text-white"
          pera="In three easy steps!"
        />
        <div className="flex flex-col justify-center pt-5">
          {/* <!-- Image --> */}
          <HeroStep counter="1" title="Start" subTitle="With taking a screen shot of your design" imgSrc="/images/page-title/3d_model.jpg" />
          <HeroStep counter="2" title="Pick" subTitle="Your DAVINTE styles or build your prompt" imgSrc="/images/page-title/promptPanel.jpg" whiteImgSrc="/images/page-title/promptPanel_white.jpg" />
          <HeroStep counter="3" title="Upscale" subTitle="Your favorite generations" imgSrc="/images/page-title/generations.jpg" whiteImgSrc="/images/page-title/generations_white.jpg" />
          <HeroStep counter="4" title="Download" subTitle="The final rendering" imgSrc="/images/page-title/3d_rendered.jpg" />
        </div>
      </div>
    </section>
  );
};

export default HeroSteps;
