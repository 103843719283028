import React, { useRef, useState, useEffect } from "react";
import Image from 'next/image'
import { defaultBlurData } from "../../../utils/common"

export default function ExploreHeroItem(props) {
  const { design, idea } = props;
  const [isSource, setIsSource] = useState(false);
  const [sourceImage, setSourceImage] = useState(process.env.NEXT_PUBLIC_DO_SPACE_CDN_URL + "/" + idea["imagePath"]);
  const handleClick = () => {
    setIsSource(!isSource);
  };

  useEffect(() => {
    if (!isSource) {
        setSourceImage(process.env.NEXT_PUBLIC_DO_SPACE_CDN_URL + "/" + idea["imagePath"]);
    }
    else {
        setSourceImage(design['targetCdnImageUrl']);
    }
  }, [isSource])

  return (
    <div className="w-full block relative group">
      <Image width={idea['imageWidth']} height={idea['imageHeight']} loading="lazy"
        className="w-full block rounded"
        // placeholder={'blur'}
        // blurDataURL={design["blurredDataUrl"]? design["blurredDataUrl"] : defaultBlurData}
        src={sourceImage}
        alt="Davinte | AI Platform for Architects and Interior Designers - AI POWERED Rendering ENGINE"
      />
      {/* <div className="absolute text-lg top-0 left-0 w-full bg-gradient-to-t from-black to-transparent hidden group-hover:block">
        <h1>
            {design["userPrompt"]}
        </h1>
      </div> */}
      <div className="absolute bottom-0 right-0 mr-3 mb-1">
        <i
          className={`cursor-pointer text-lg text-white dark:text-white ${!isSource ? "fal fa-image" : "fad fa-image"}`}
          onClick={handleClick}
        ></i>
      </div>

    </div>
  );
}
