import { HeadLine } from "../component";
import PlanList from "./planList";
import { PlansListType } from "../../utils/enums";

export default function PlanHero() {
  return (
    <section className="relative md:py-24 py-12 ">
      <div className="container">
        <HeadLine
          text="Subscription Plans"
          classes="font-hero-main font-title-small text-jacarta-700 mb-2 px-5 md:px-10 md:mb-6 text-center  text-5xl lg:text-6xl dark:text-white"
          pera="Start with a 3 days Trial - No Credit Card Required"
        />
        <div className="relative flex justify-center">
          {/* <!-- Slider --> */}
          <PlanList displayType={PlansListType.SWIPER} />
        </div>
      </div>
    </section>
  );
}
