import React, { useState, useEffect } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "tippy.js/dist/tippy.css";
import { HeadLine } from "../component";

const KeyFeatures = () => {
    const [hasWindow, setHasWindow] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setHasWindow(true);
        }
    }, []);
    return (
        <div style={{ backgroundColor: 'rgb(250 250 250)' }}>
            <section className="dark:bg-jacarta-800 relative md:py-24 py-12">
                <picture className="pointer-events-none absolute inset-0 -z-10 hidden">
                    <img
                        src="/images/gradient_light.jpg"
                        alt="gradient"
                        className="h-full w-full"
                    />
                </picture>

                <div className="container">
                    <HeadLine
                        text="Key Capabilities"
                        classes="font-display text-jacarta-700 mb-6 text-center text-2xl md:text-3xl lg:text-4xl dark:text-white"
                        pera=""
                    />
                    <div className="flex flex-col justify-center">
                        {/* <!-- Image --> */}
                        <div className="w-full flex flex-col justify-center align-middle my-16 mt-10">
                            <h2 className="text-jacarta-700 font-display text-center mb-2 text-xl dark:text-white">
                                Ideate with just a photo
                            </h2>
                            <p className="text-jacarta-700 dark:text-jacarta-200 text-md mb-6 text-center">
                                Explore the potential of your space, with just a photo.
                            </p>
                            <div className="relative">
                                <img
                                    src="/images/gifs/improve_design.gif"
                                    className="mx-auto mt-8 lg:w-[80%] rounded-lg" />
                            </div>
                        </div>
                        <div className="w-full flex flex-col justify-center align-middle my-16">
                            <h2 className="text-jacarta-700 font-display text-center mb-2 text-2xl dark:text-white">
                                Give birth to a sketch
                            </h2>
                            <p className="text-jacarta-700 dark:text-jacarta-200 text-md mb-6 text-center">
                                Create a full design with just a sketch.
                            </p>
                            <div className="relative">
                                <img
                                    src="/images/gifs/sketch.gif"
                                    className="mx-auto mt-8 lg:w-[80%] rounded-lg" />
                            </div>
                        </div>
                        <div className="w-full flex flex-col justify-center align-middle my-16">
                            <h2 className="text-jacarta-700 font-display text-center mb-6 text-2xl dark:text-white">
                                Magic edit
                            </h2>
                            <p className="text-jacarta-700 dark:text-jacarta-200 text-md mb-6 text-center">
                                Change every part or aspect of your space. Either your photo or the AI generated design.
                            </p>
                            <div className="relative">
                                <img
                                    src="/images/gifs/magic_edit.gif"
                                    className="mx-auto mt-8 lg:w-[80%] rounded-lg" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default KeyFeatures;
